<template>
  <div>
    <b-row>
      <b-col cols="12" lg="12">
        <!--table sellPoint invoice-->
        <ek-table
          :items="sellpoints"
          :columns="sellPointCols"
          @details="goToDetails"
          no_delete
        >
          <template slot="items.subscribeDate" slot-scope="{ value }">
            {{ value ? new Date(value).toLocaleDateString() : "_" }}
          </template>

          <template slot="items.lastPaidDate" slot-scope="{ value }">
            {{ value ? new Date(value).toLocaleDateString() : "_" }}
          </template>
        </ek-table>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  props: {
    id: String,
  },
  computed: {
    ...mapState({
      sellpoints: ({ invoiceSellPoint }) => invoiceSellPoint.sellpoints,
      sellPointCols: ({ invoiceSellPoint }) => invoiceSellPoint.sellPointCols,
    }),
    ...mapGetters(["sellPointList"]),
  },
  data: () => ({}),
  methods: {
    ...mapActions(["getSellPointsList", "deleteSellPoints","getGetUnRead"]),
    goToDetails({ row }) {
      let rowDotName = row.name;
      this.$router.push({
        path: `/admin/invoiceSellPoint/${row.id}`,
        query: { name: rowDotName },
      });
    },
  },
  created() {
    this.getSellPointsList();
    this.getGetUnRead();
  },
};
</script>
